import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import BackBar from '@components/BackBar';
import axios from 'axios';
import * as config from '@config';
import styles from '@styles/AccommPage.module.css';
import { Loader } from 'semantic-ui-react';
import { FaFilePdf } from 'react-icons/fa';
import { ScrollToTop } from '@utils/scrollPosition';
import { useLayoutEffect } from 'react';
import { OpenURLFile } from '@utils/utils';
import TermsCondations from '@components/TermsCondations';
import { GetToken } from '@utils/storage';

function AccommPage() {
    const { id } = useParams();
    const [hotel, setHotel] = useState({});
    const [fetching, setFetching] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        // console.log(id);
        fetchHotel(id);
        return () => {
            if (fetching) controller.abort();
        };
    }, []);

    useLayoutEffect(() => {
        ScrollToTop();
    }, []);

    const fetchHotel = async (id) => {
        setFetching(true);
        try {
            const res = await axios.get(`${config.apiURL}/hotel/${id}`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            if (res.data.error) {
                window.alert(res.data.error);
            } else if (res.data.hotel) {
                // console.log(res.data);
                setHotel(res.data.hotel);
            }
        } catch (error) {
            const errMsg = error.response?.data?.error;
            if (!!errMsg) {
                window.alert(errMsg);
            } else {
                window.alert(error);
            }
        }
        setFetching(false);
    };

    return (
        <>
            <BackBar />
            <div className={`${styles.container}`}>
                <div className={styles.images_container}>
                    {hotel?.photos?.map((item, index) => {
                        return (
                            <div key={index} className={styles.img_container}>
                                <img
                                    src={`${config.baseURL}/public/${item.filename}`}
                                    onError={(e) => (e.currentTarget.src = `${config.baseURL}/public/empty_image.png`)}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className={styles.content_container}>
                    <Loader active={fetching} inline="centered"></Loader>
                    {!fetching && (
                        <>
                            <div className={styles.pricing_container}>A${hotel.startingPrice} 起</div>
                            <div
                                className={`${styles.description_container} workaround_description}`}
                                dangerouslySetInnerHTML={{
                                    __html: hotel.content,
                                }}
                            />
                            <div className={styles.brochures_container}>
                                {hotel?.brochures?.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.brochure_container}>
                                            <FaFilePdf className={styles.icon} />
                                            <div
                                                className={styles.filename}
                                                onClick={() => OpenURLFile(item.filename)}
                                                title={item.filename}
                                            >
                                                {item.filename}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={styles.terms_condations_container}>
                                <TermsCondations />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default AccommPage;
