import React from 'react';
import styles from '@styles/AccommHomePage.module.css';
import SelectorsForm from '@components/SelectorsForm';
import { Loader } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AccommodationSelectorOptions } from '@utils/constans';
import { useSelector } from 'react-redux';

function HomePage() {
    const navigate = useNavigate();
    const fetching = useSelector((state) => state.accommodations.fetching);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit = () => {
        const params = new URLSearchParams(searchParams);
        navigate(`search?${params.toString()}`);
    };

    return (
        <div className={styles.container}>
            <section className={`${styles.form_section}`}>
                <div className={styles.label_container}>住宿</div>
                <div className={styles.form_container}>
                    <SelectorsForm
                        tagOptions={AccommodationSelectorOptions}
                        option={AccommodationSelectorOptions}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </section>
            <Loader active={fetching} inline="centered">
                Loading
            </Loader>
        </div>
    );
}

export default HomePage;
