import React, { useEffect, useRef } from 'react';
import LodgingForm from '@components/hotel/LodgingForm';
import { useNavigate } from 'react-router';
import styles from '@styles/HotelHomePage.module.css';
import qs from 'qs';
import { Selector, SelectorParser } from '@utils/constans';
import * as config from '@config';
import { RemoveHotelAvailData } from '@utils/storage';
import TopHeaderBg from '@assets/hotel_top_bg.png';

function HomePage() {
    const navigate = useNavigate();

    useEffect(() => {
        RemoveHotelAvailData();
    }, []);

    const handleSubmit = (formReq) => {
        formReq.timestamp = Date.now();
        const queryString = qs.stringify(formReq);
        navigate({
            pathname: 'results',
            search: `?${queryString}`,
        });
    };

    const Hightlights = () => {
        const ref = useRef();

        const handleOpen = () => {
            ref.current.showModal();
        };

        return (
            <section className={styles.highlights_container}>
                {[
                    { selector: Selector.Sydney },
                    { selector: Selector.Melbourne },
                    { selector: Selector.Brisbane },
                    { selector: Selector.GoldCoast },
                    { selector: Selector.Perth },
                    { selector: Selector.Cairns },
                    { selector: Selector.Adelaide },
                    { selector: Selector.Canberra },
                ].map((item, index) => {
                    return (
                        <div key={index} className={styles.highlight_container} onClick={handleOpen}>
                            <div
                                className={styles.image_container}
                                style={{
                                    backgroundImage: `url('${config.baseURL}/images/place_${item.selector}.webp')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            <div className={styles.caption}>{SelectorParser(item.selector)}</div>
                        </div>
                    );
                })}
                <dialog ref={ref}>
                    <div className={styles.dialog_content}>
                        <div className="">Coming soon...</div>
                        <button className="primary_btn slim_btn greyscale" onClick={() => ref.current.close()}>
                            Close
                        </button>
                    </div>
                </dialog>
            </section>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.works_container}>
                    <div className={styles.paragraph}>酒店</div>
                    {/* <div className={styles.paragraph}>
                        全球航线 <span className={styles.colorful}>1000+</span> 条自在飞
                    </div> */}
                </div>
            </div>
            <div className={styles.content_container}>
                <div className={styles.search_container}>
                    <LodgingForm onSubmit={handleSubmit} initShowHistory={true} />
                </div>
                <Hightlights />
            </div>
        </div>
    );
}

export default HomePage;
