import React, { useState, useEffect } from 'react';
import styles from '@styles/SearchPage.module.css';
import SelectorsForm from '@components/SelectorsForm';
import { Loader } from 'semantic-ui-react';
import Accommodations from '@components/Accommodations';
import { useSearchParams } from 'react-router-dom';
import { AccommodationSelectorOptions, CATAccomParse } from '@utils/constans';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

function SearchPage() {
    const [data, setData] = useState([]);
    // Redux Memoized Selectors
    const selectorData = (state) => state.accommodations.data;
    const filterHiden = createSelector(selectorData, (items) => items?.filter((item) => item.hidden == false));
    const accommodations = useSelector((state) => {
        return filterHiden(state);
    });
    const fetching = useSelector((state) => state.accommodations.fetching);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        filterAccomms();
    }, []);

    useEffect(() => {
        filterAccomms();
    }, [fetching]);

    const filterAccomms = () => {
        const params = new URLSearchParams(searchParams);
        let selector = params.get('selector');
        let cats = params.getAll('cats');
        let text = params.get('text');
        const sort = params.get('sort');
        let newData = [...accommodations];
        if (selector == 'all') {
            selector = '';
        }
        if (!!text) {
            newData = accommodations.filter((item) => {
                if (
                    item.name?.toLowerCase().includes(text.toLowerCase()) ||
                    item.nameEN?.toLowerCase().includes(text.toLowerCase())
                ) {
                    return true;
                }
            });
        }
        //NOTE: no cats means all cats
        if (cats.length > 0) {
            // console.log('cats', cats);
            newData = newData.filter((item) => {
                if (cats.includes(CATAccomParse(item.cat))) {
                    return true;
                }
            });
        }
        if (selector) {
            newData = newData.filter((item) => {
                if (item.selectors?.includes(selector)) {
                    return true;
                }
            });
            //sorting
            newData?.sort((a, b) => {
                const sortA = a.selectorItems.find((item) => item.name == selector).sort;
                const sortB = b.selectorItems.find((item) => item.name == selector).sort;
                return sortA - sortB;
            });
        }
        if (sort) {
            switch (sort) {
                case 'price-asc':
                    newData?.sort((a, b) => {
                        return a.startingPrice - b.startingPrice;
                    });
                    break;
                case 'price-desc':
                    newData?.sort((a, b) => {
                        return b.startingPrice - a.startingPrice;
                    });
                    break;
                default:
                    break;
            }
        }
        setData(newData);
    };

    const handleSearch = () => {
        filterAccomms();
    };

    return (
        <div className={styles.container}>
            <section className={`${styles.form_section}`}>
                <div className={styles.form_container}>
                    <SelectorsForm
                        option={AccommodationSelectorOptions}
                        // handleSubmit={handleSearch}
                    />
                </div>
            </section>
            <div className={`${styles.table_container}`}>
                <Accommodations accommodations={data} callback={handleSearch} />
            </div>
            <Loader active={fetching} inline="centered">
                Loading
            </Loader>
        </div>
    );
}

export default SearchPage;
