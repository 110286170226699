import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/LodgingForm.module.css';
import styles_input from '@styles/InputContainer.module.css';
import { MdOutlineLocationOn, MdCalendarToday, MdOutlineBed } from 'react-icons/md';
import Floated from '@components/Floated';
import CityPicker from '@components/CityPicker';
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';
import '@styles/day-picker.css';
import RoomsPicker2, { DefaultRoomsData, RoomsValidate } from './RoomsPicker2';
import { Icon } from 'semantic-ui-react';
import { SabreHotelRateSource, SabreHotelRateSourceText } from '../../utils/constans';
import { addDays, format } from 'date-fns';
import { GetHotelSearchQueryHistory, SaveToHotelSearchHistory } from '../../utils/storage';
import { AustralianCities } from '../CityPicker/constant.js';
import { Checkbox, Radio, Space } from 'antd';

const protectionDays = 7;

const defaultData = {
    airportCode: '',
    cityCode: '',
    countryCode: '',
    cityName: '',
    startDate: '',
    endDate: '',
    rooms: [...DefaultRoomsData],
    currencyCode: '',
    rateSource: [],
    minRating: '0.5',
    maxRating: '5',
    refundableOnly: 'true', // Make sure it's a string
};

const defaultRange = {
    from: '',
    to: '',
};

const defaultError = {
    location: null,
    dates: null,
    rooms: null,
};

function LodgingForm({ onSubmit = () => {}, initData = {}, noLocation = false, initShowHistory = false }) {
    const [data, setData] = useState(defaultData); // request data
    const [locationPickerOpen, setLocationPickerOpen] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [roomPickerOpen, setRoomPickerOpen] = useState(false);
    const [range, setRange] = useState(defaultRange);
    const [roomError, setRoomError] = useState(); //use to look room picker float
    const [errors, setErrors] = useState(defaultError);
    const [showHistory, setShowHistory] = useState(initShowHistory);

    useEffect(() => {
        if (Object.keys(initData).length != 0) {
            setData((prev) => {
                return { ...prev, ...initData };
            });
            // Set Calender range
            if (initData.startDate) {
                setRange((prev) => {
                    return { ...prev, from: new Date(initData.startDate) };
                });
            }
            if (initData.endDate) {
                setRange((prev) => {
                    return { ...prev, to: new Date(initData.endDate) };
                });
            }
        }
    }, [initData]);

    useEffect(() => {
        console.log('range', range);
        if (range?.from) {
            setData((prev) => {
                return { ...prev, startDate: dayjs(range.from).format('YYYY-MM-DD') };
            });
        } else {
            setData((prev) => {
                return { ...prev, startDate: '' };
            });
        }
        if (range?.to) {
            setData((prev) => {
                return { ...prev, endDate: dayjs(range.to).format('YYYY-MM-DD') };
            });
        } else {
            setData((prev) => {
                return { ...prev, endDate: '' };
            });
        }
    }, [range]);

    useEffect(() => {
        const error = RoomsValidate(data.rooms);
        setRoomError(error);
        return () => {};
    }, [data]);

    const RoomsText = (rooms) => {
        let travellerCount = 0;
        rooms?.forEach((item) => {
            travellerCount += Number(item.adult);
            travellerCount += Number(item.child);
        });
        return `${data.rooms?.length} ${data.rooms?.length > 1 ? 'rooms' : 'room'}  ${travellerCount} ${
            travellerCount > 1 ? 'persons' : 'person'
        }`;
    };

    const dateDisplayOptions = {
        month: 'short',
        day: 'numeric',
    };

    const CheckinDate = () => {
        const startDate = new Date(data.startDate).toLocaleDateString('en-AU', dateDisplayOptions);
        return `${data.startDate && `Check-in ${startDate}`}`;
    };

    const CheckoutDate = () => {
        const endDate = new Date(data.endDate).toLocaleDateString('en-AU', dateDisplayOptions);
        return `${data.endDate && `Check-out ${endDate}`}`;
    };

    const handleSubmit = () => {
        const err = {
            location: !data.cityCode ? 'Missing location' : null,
            dates: !data.startDate || !data.endDate ? 'Missing dates' : null,
            rooms: !!RoomsValidate(data.rooms) ? 'Missing rooms' : null,
        };
        setErrors(err);
        if (JSON.stringify(err) !== JSON.stringify(defaultError)) {
            return;
        }
        SaveToHotelSearchHistory(data);
        onSubmit(data);
    };

    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // console.log({ [name]: value });
        switch (name) {
            case 'currencyCode':
                setData((prev) => {
                    return { ...prev, currencyCode: value };
                });
                break;
            case 'rateSource':
                let rateSource = [...data.rateSource];
                if (rateSource.includes(value)) {
                    rateSource = rateSource.filter((item) => {
                        return item !== value;
                    });
                } else {
                    rateSource.push(value);
                }
                setData((prev) => {
                    return { ...prev, rateSource: rateSource };
                });
                break;
            case 'minRating':
                setData((prev) => {
                    return { ...prev, minRating: value };
                });
                break;
            case 'maxRating':
                setData((prev) => {
                    return { ...prev, maxRating: value };
                });
                break;
            case 'refundableOnly':
                setData((prev) => {
                    if (prev.refundableOnly == 'true') {
                        return { ...prev, refundableOnly: 'false' };
                    }
                    return { ...prev, refundableOnly: 'true' };
                });
                break;
            default:
                break;
        }
    };

    const handleHistoryClick = (data) => {
        setData((prev) => {
            return { ...prev, ...data };
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.group_container}>
                {noLocation == false && (
                    <div className={`${styles.group_item_container} ${styles.location_container}`}>
                        <div className={styles_input.input_container}>
                            <MdOutlineLocationOn className={styles_input.icon} />
                            <input
                                className={styles_input.input}
                                placeholder="Airport/City"
                                defaultValue={data.cityName}
                                onClick={() => setLocationPickerOpen(true)}
                                error={errors.location}
                            />
                            <Floated active={locationPickerOpen} setActive={setLocationPickerOpen} modal={false} fluid>
                                <CityPicker
                                    cityOnly={true}
                                    active={locationPickerOpen}
                                    placeholder="Airport/City/Destination"
                                    defaultValue={data.cityName}
                                    defaultResults={AustralianCities}
                                    callback={(value) => {
                                        console.log(value);
                                        setData((prev) => {
                                            return {
                                                ...prev,
                                                cityName: value.cityEnglishName,
                                                cityCode: value.cityCode,
                                                airportCode: value.airportCode,
                                                countryCode: value.countryCode,
                                            };
                                        });
                                        setLocationPickerOpen(false);
                                    }}
                                />
                            </Floated>
                        </div>
                    </div>
                )}
                <div className={`${styles.group_item_container} ${styles.date_container}`}>
                    <div className={styles_input.input_container} onClick={() => setOpenCalendar(true)}>
                        <MdCalendarToday className={styles_input.icon} />
                        <input
                            className={styles_input.input}
                            placeholder="Check-in Date"
                            value={CheckinDate()}
                            readOnly
                            error={errors.dates}
                        />
                        {/* <button className={styles_input.trigger_btn} onClick={() => setOpenCalendar(true)} /> */}
                    </div>
                    {/* NOTE: openCalendar is used to let defaultMonth take effect */}
                    {openCalendar && (
                        <Floated
                            active={openCalendar}
                            setActive={setOpenCalendar}
                            modal={false}
                            onClose={() => console.log('Float close')}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    height: '100%',
                                }}
                            >
                                <DayPicker
                                    // style={{ border: '1px solid black' }}
                                    mode={'range'}
                                    numberOfMonths={2}
                                    captionLayout="dropdown-buttons"
                                    fromYear={new Date().getFullYear()}
                                    toYear={new Date().getFullYear() + 2}
                                    selected={range}
                                    onSelect={setRange}
                                    disabled={[{ from: new Date(0), to: addDays(new Date(), protectionDays) }]}
                                    defaultMonth={data.startDate && new Date(data.startDate)}
                                />
                                <div style={{ paddingBottom: '16px', paddingRight: '16px' }}>
                                    <p>{data.startDate && dayjs(data.startDate).format('YYYY-MM-DD')}</p>
                                    <p>{data.endDate && dayjs(data.endDate).format('YYYY-MM-DD')}</p>

                                    <button className="secondary_btn" onClick={() => setOpenCalendar(false)}>
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </Floated>
                    )}
                </div>
                <div className={`${styles.group_item_container} ${styles.date_container}`}>
                    <div className={styles_input.input_container} onClick={() => setOpenCalendar(true)}>
                        <MdCalendarToday className={styles_input.icon} />
                        <input
                            className={styles_input.input}
                            placeholder="Check-out Date"
                            value={CheckoutDate()}
                            readOnly
                            error={errors.dates}
                        />
                    </div>
                </div>
                <div className={`${styles.group_item_container} ${styles.room_container}`}>
                    <div className={styles_input.input_container} onClick={() => setRoomPickerOpen(true)}>
                        <MdOutlineBed className={styles_input.icon} />
                        <input
                            className={styles_input.input}
                            type="text"
                            placeholder="房间"
                            readOnly
                            value={RoomsText(data.rooms)}
                            error={errors.rooms}
                        />
                        <Floated
                            active={roomPickerOpen}
                            setActive={setRoomPickerOpen}
                            modal={false}
                            fluid
                            locked={!!roomError}
                        >
                            <RoomsPicker2
                                rooms={data.rooms}
                                setRooms={(rooms) =>
                                    setData((prev) => {
                                        return { ...prev, rooms: rooms };
                                    })
                                }
                            />
                        </Floated>
                    </div>
                </div>

                <div className={`${styles.group_item_container} ${styles.search_container}`}>
                    <div className={styles.action_container}>
                        <button className="primary_btn" onClick={handleSubmit}>
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.options_container} aria-hidden={noLocation}>
                <div className={styles.more_options}>
                    <div className={styles.top_options}>
                        <Space>
                            <Checkbox
                                name="refundableOnly"
                                checked={data.refundableOnly == 'true'}
                                onChange={handleOnChange}
                            >
                                Refundable Only
                            </Checkbox>
                            <Checkbox
                                name="rateSource"
                                value={SabreHotelRateSource.GDS}
                                checked={data.rateSource.includes(SabreHotelRateSource.GDS)}
                                onChange={handleOnChange}
                            >
                                System S
                            </Checkbox>
                            <Checkbox
                                name="rateSource"
                                value={SabreHotelRateSource.Booking}
                                checked={data.rateSource.includes(SabreHotelRateSource.Booking)}
                                onChange={handleOnChange}
                            >
                                System B
                            </Checkbox>
                        </Space>
                        <Radio.Group
                            name="currencyCode"
                            onChange={handleOnChange}
                            defaultValue={'AUD'}
                            value={data.currencyCode || 'AUD'}
                        >
                            <Radio value="AUD">AUD</Radio>
                            <Radio value="CNY" disabled>
                                CNY
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            <div className={styles.search_history_container}>
                {GetHotelSearchQueryHistory()?.length > 0 && (
                    <div className={styles.history_trigger_container}>
                        <span className={styles.history_trigger} onClick={() => setShowHistory((prev) => !prev)}>
                            Recent Search
                            <Icon name={showHistory ? 'caret down' : 'caret right'} />
                        </span>
                    </div>
                )}

                <div className={styles.search_content_container} aria-hidden={!showHistory}>
                    {GetHotelSearchQueryHistory().map((item, index) => {
                        return (
                            <div key={index} className={`${styles.search_history_item} anchor`}>
                                <a onClick={() => handleHistoryClick(item)}>
                                    <span>{item.cityCode}</span>
                                    <span>
                                        {format(new Date(item.startDate), 'MMMd')}-
                                        {format(new Date(item.endDate), 'MMMd')}
                                    </span>
                                    <span>{SabreHotelRateSourceText(item.rateSource)}</span>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

LodgingForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initData: PropTypes.object,
    noLocation: PropTypes.bool,
    initShowHistory: PropTypes.bool,
};

export default LodgingForm;
