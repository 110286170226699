import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/FilterLayout.module.css';
import { CATAccom, DestinationName } from '@utils/constans';
import { useSearchParams } from 'react-router-dom';

function AccommsFilter({ replace = true }) {
    const [cats, setCats] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const cats = params.getAll('cats') || [];
        setCats(cats);
    }, [searchParams]);

    const handleOnChangeFilter = (e) => {
        const name = e.target.getAttribute('name');
        let value = e.target.getAttribute('value');
        // workaround select tag
        if (!!value == false) {
            value = e.target.value;
        }
        // console.log({ [name]: value });
        const params = new URLSearchParams(searchParams);
        switch (name) {
            case 'cat':
                const cats = params.getAll('cats') || [];
                if (cats.includes(value)) {
                    const newCats = cats.filter((item) => item != value);
                    params.delete('cats');
                    newCats.forEach((item, index) => {
                        if (index == 0) {
                            params.set('cats', item);
                        } else {
                            console.log(item);
                            params.append('cats', item);
                        }
                    });
                } else {
                    params.append('cats', value);
                }
                setSearchParams(params, { replace: replace });
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.sector_container}>
                <div className={styles.title}>住宿类别</div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="cat"
                            value={CATAccom.Hotel}
                            type="checkbox"
                            id={CATAccom.Hotel}
                            onChange={handleOnChangeFilter}
                            checked={cats.includes(CATAccom.Hotel)}
                        />
                        <label htmlFor={CATAccom.Hotel}>酒店</label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="cat"
                            value={CATAccom.Apartment}
                            type="checkbox"
                            id={CATAccom.Apartment}
                            onChange={handleOnChangeFilter}
                            checked={cats.includes(CATAccom.Apartment)}
                        />
                        <label htmlFor={CATAccom.Apartment}>公寓</label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="cat"
                            value={CATAccom.House}
                            type="checkbox"
                            id={CATAccom.House}
                            onChange={handleOnChangeFilter}
                            checked={cats.includes(CATAccom.House)}
                        />
                        <label htmlFor={CATAccom.House}>豪华别墅</label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="cat"
                            value={CATAccom.Camp}
                            type="checkbox"
                            id={CATAccom.Camp}
                            onChange={handleOnChangeFilter}
                            checked={cats.includes(CATAccom.Camp)}
                        />
                        <label htmlFor={CATAccom.Camp}>露营体验</label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="cat"
                            value={CATAccom.Package}
                            type="checkbox"
                            id={CATAccom.Package}
                            onChange={handleOnChangeFilter}
                            checked={cats.includes(CATAccom.Package)}
                        />
                        <label htmlFor={CATAccom.Package}>住宿套餐</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

AccommsFilter.propTypes = {
    replace: PropTypes.bool,
};

export default AccommsFilter;
