import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Popup,
    Icon,
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    TableHeader,
    TableHeaderCell,
    ModalActions,
} from 'semantic-ui-react';
import styles from '@styles/HotelsTable.module.css';
import { SabreHotelRateSourceText } from '../../utils/constans';
import useHotelMedia from '../../hooks/hotel/useHotelMedia';
import Gallery from '@components/Gallery';
import { GetNightsBetweenDates } from './RoomsTable';
import { Checkbox, Space } from 'antd';

const FilterConst = {
    Rating: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
    },
    Sort: {
        PriceAsc: 'price_asc',
        PriceDesc: 'price_desc',
    },
};

const defaultFilterSettings = {
    ratings: [4, 5],
    sort: FilterConst.Sort.PriceAsc,
};

function HotelsTable({ hotels = [], onSubmit = () => {} }) {
    const selectRef = useRef();
    const [data, setData] = useState(hotels); // filtered hotels
    const [filterSettings, setFilterSettings] = useState(defaultFilterSettings);

    useEffect(() => {
        appleFilters(filterSettings);
    }, [hotels, filterSettings]);

    const appleFilters = (settings) => {
        // console.log(settings);
        // filter by ratings
        let filteredData = hotels.filter((item) => {
            if (settings.ratings.length > 0) {
                const rating = Math.floor(Number(item.hotelInfo.sabreRating));
                if (!settings.ratings.includes(rating)) {
                    return false;
                }
            }
            return true;
        });
        // sort by price
        filteredData.sort((a, b) => {
            const priceA = parseFloat(a.hotelRateInfo.rateInfos[0].amountAfterTax);
            const priceB = parseFloat(b.hotelRateInfo.rateInfos[0].amountAfterTax);
            switch (settings.sort) {
                case FilterConst.Sort.PriceAsc:
                    return priceA - priceB;
                case FilterConst.Sort.PriceDesc:
                    return priceB - priceA;
                default:
                    return priceA - priceB;
            }
        });
        setData(filteredData);
    };

    const handleFilterChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log({ [name]: value });
        switch (name) {
            case 'rating':
                const selectedRating = parseInt(value);
                // Unselect if already containered
                if (filterSettings.ratings.includes(selectedRating)) {
                    const newRatings = filterSettings.ratings.filter((item) => item !== selectedRating);
                    setFilterSettings({
                        ...filterSettings,
                        ratings: newRatings,
                    });
                } else {
                    setFilterSettings({
                        ...filterSettings,
                        ratings: [...filterSettings.ratings, selectedRating],
                    });
                }
                break;
            case 'sort':
                // according to sort value
                switch (value) {
                    case FilterConst.Sort.PriceAsc:
                        console.log('desc');
                        //set filterSettings
                        setFilterSettings((prev) => {
                            return { ...prev, sort: FilterConst.Sort.PriceAsc };
                        });
                        break;
                    case FilterConst.Sort.PriceDesc:
                        console.log('asc');
                        //set filterSettings
                        setFilterSettings((prev) => {
                            return { ...prev, sort: FilterConst.Sort.PriceDesc };
                        });
                        break;
                    default:
                        break;
                }
                break;
            default:
                console.warn('Unkonwn filter name');
                break;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.top_container}>
                <div className={styles.leading_container}>
                    <div className={styles.result_number}>{data?.length > 0 && `${data.length} results`}</div>
                    <div className={styles.filter_container}>
                        <Space>
                            {Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        name="rating"
                                        value={FilterConst.Rating[item]}
                                        checked={filterSettings.ratings.includes(FilterConst.Rating[item])}
                                        onChange={handleFilterChange}
                                    >
                                        Rating{item}.0
                                    </Checkbox>
                                );
                            })}
                        </Space>
                    </div>
                </div>

                <div className={styles.sort_container}>
                    <select name="sort" onChange={handleFilterChange}>
                        <option value={FilterConst.Sort.PriceAsc}>Price(Low to Hight)</option>
                        <option value={FilterConst.Sort.PriceDesc}>Price(High to Low)</option>
                    </select>
                </div>
            </div>
            <Table celled>
                <TableBody>
                    {data.map((item, index) => {
                        return <HotelsTableRow key={index} index={index + 1} data={item} onSubmit={onSubmit} />;
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

HotelsTable.propTypes = {
    hotels: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
};

export default HotelsTable;

function HotelsTableRow({ index = '', data = {}, onSubmit = () => {} }) {
    const { hotelInfo, hotelRateInfo, hotelImageInfo } = data;
    const rateInfo = hotelRateInfo.rateInfos[0] ?? {};
    const rateKey = hotelRateInfo.rateInfos[0].rateKey; // lowest price
    const [open, setOpen] = useState(false);

    const Rooms = () => {
        // Get unique plans, normally there is only one plan
        let plans = hotelRateInfo.rooms.map((r) => r.ratePlans.find((p) => p.rateKey == rateKey));
        plans = plans.filter((obj, index, self) => index === self.findIndex((t) => t.productCode === obj.productCode));

        // console.log(plans);

        if (plans.length == 0) {
            return null;
        }
        return (
            <>
                {plans.map((plan, index) => {
                    return (
                        <div key={index}>
                            <div>{plan.ratePlanName}</div>
                            <div>
                                {plan.convertedRateInfo.cancelPenalties[0].refundable && (
                                    <>
                                        Refundable{' '}
                                        <Popup trigger={<Icon name="info circle" />} content="Subject to hotel" />
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const Amenities = () => {
        const [open, setOpen] = useState(false);
        return (
            <div>
                <a style={{ whiteSpace: 'nowrap' }} onClick={() => setOpen(true)}>
                    Amenities
                </a>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalHeader>Amenities</ModalHeader>
                    <ModalContent>
                        <div>
                            {hotelInfo.amenities.map((item, index) => {
                                return <div key={index}>{item.description}</div>;
                            })}
                        </div>
                    </ModalContent>
                    <ModalActions>
                        <Button className="primary_btn" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </ModalActions>
                </Modal>
            </div>
        );
    };

    const HotelImages = () => {
        const [open, setOpen] = useState(false);
        const { fetching, imageUrls, fetchData, abort } = useHotelMedia({
            hotelCode: hotelInfo.hotelCode,
            codeContext: hotelInfo.codeContext,
        });

        useEffect(() => {
            if (open == true && imageUrls.length == 0) {
                fetchData();
            }
            return () => {
                abort();
            };
        }, [open]);

        return (
            <div>
                <a style={{ whiteSpace: 'nowrap' }} onClick={() => setOpen(true)}>
                    Pic
                </a>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalHeader>Pictures</ModalHeader>
                    <ModalContent>
                        {fetching && <div>Loading...</div>}
                        <div>
                            <Gallery urls={imageUrls} />
                        </div>
                    </ModalContent>
                    <ModalActions>
                        <Button className="primary_btn" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </ModalActions>
                </Modal>
            </div>
        );
    };

    const handleSubmit = (rateKey) => {
        onSubmit({
            rateKey: rateKey,
            hotelCode: hotelInfo.hotelCode,
            codeContext: hotelInfo.codeContext,
        });
    };

    return (
        <TableRow>
            <TableCell onClick={() => console.log(data)} textAlign="center">
                {index}
            </TableCell>
            <TableCell>
                <div className={styles.image_container}>
                    <img src={hotelImageInfo?.imageItem.image.url ?? hotelInfo.logo} alt="" />
                </div>
            </TableCell>
            <TableCell>
                <div className={styles.hotel_info_container}>
                    <div>{hotelInfo.hotelName}</div>
                    {/* <div>{hotelInfo.chainName && `${hotelInfo.chainName}`}</div> */}
                    <div className={styles.address}>{hotelInfo.location.fullAddress}</div>
                </div>
            </TableCell>
            <TableCell>
                <HotelImages />
            </TableCell>
            <TableCell>
                <Amenities />
            </TableCell>
            <TableCell>{hotelInfo.sabreRating}</TableCell>
            <TableCell>{Rooms()}</TableCell>
            <TableCell>
                {rateInfo.currencyCode} {rateInfo.amountAfterTax}
            </TableCell>
            <TableCell>
                Commission: {rateInfo.currencyCode} {rateInfo.commission2}
            </TableCell>
            <TableCell>
                <Button className="primary_btn" compact onClick={() => setOpen(true)}>
                    Availability ({hotelRateInfo.rateInfos.length})
                </Button>
            </TableCell>
            <Modal open={open} onClose={() => setOpen(false)} closeIcon>
                <ModalHeader>{hotelInfo.hotelName}</ModalHeader>
                <ModalContent>
                    <Table celled>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Check-in</TableHeaderCell>
                                <TableHeaderCell>Check-out</TableHeaderCell>
                                <TableHeaderCell>Stay</TableHeaderCell>
                                <TableHeaderCell>Before Tax</TableHeaderCell>
                                <TableHeaderCell>After Tax</TableHeaderCell>
                                <TableHeaderCell>Price/Night</TableHeaderCell>
                                <TableHeaderCell>System</TableHeaderCell>
                                <TableHeaderCell>Actions</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {hotelRateInfo.rateInfos.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{item.startDate}</TableCell>
                                        <TableCell>{item.endDate}</TableCell>
                                        <TableCell>
                                            {GetNightsBetweenDates(item.startDate, item.endDate)}Night
                                        </TableCell>
                                        <TableCell>
                                            {item.currencyCode} {item.amountBeforeTax}
                                        </TableCell>
                                        <TableCell>
                                            {item.currencyCode} {item.amountAfterTax}
                                        </TableCell>
                                        <TableCell>
                                            {item.currencyCode} {item.averageNightlyRate}
                                        </TableCell>
                                        <TableCell>{SabreHotelRateSourceText(item.rateSource)}</TableCell>
                                        <TableCell>
                                            <Button
                                                className="primary_btn"
                                                compact
                                                onClick={() => handleSubmit(item.rateKey)}
                                            >
                                                See Availability
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </ModalContent>
            </Modal>
        </TableRow>
    );
}

HotelsTableRow.propTypes = {
    data: PropTypes.shape({
        hotelInfo: PropTypes.object.isRequired,
        hotelRateInfo: PropTypes.object.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};
